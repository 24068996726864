import { createTheme } from '@mui/material/styles'

import InterVariable from 'src/fonts/Inter-roman.var.woff2'

const baseSpacing = 8

const bodyFont = '"Inter", "Helvetica", "Arial", sans-serif'
const headerFont = bodyFont

// A custom theme for this app
const theme = createTheme({
  spacing: baseSpacing,
  palette: {
    text: {
      primary: '#161616',
    },
    primary: {
      main: '#5068EB', // Blue, main campaign color
    },
    secondary: {
      main: '#4CAF50', // Green, CTA
    },
    background: {
      primary: '#eff2fe',
      trackingNotice: '#FBA966',
      gray: '#F9F9FD',
    },
  },
  typography: {
    body: {
      fontFamily: bodyFont,
    },
    h1: {
      fontFamily: headerFont,
      fontSize: '2.75rem',
      fontWeight: 600,
    },

    h2: {
      fontFamily: headerFont,
      fontSize: '1.75rem',
      fontWeight: 600,
    },

    h3: {
      fontFamily: headerFont,
      fontSize: '1.25rem',
      fontWeight: 600,
    },

    accordion: {
      fontWeight: 500,
    },
    tag: {
      // color = secondary
      color: '#666666',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
        },
        '@font-face': {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: '100 900',
          src: `url(${InterVariable}) format('woff2')`,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            // padding: `${baseSpacing * 2}px ${baseSpacing * 3}px`,
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none', // no capitalize
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // no capitalize
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 3,
      },
    },
  },
})

export default theme
