import produce from 'immer'

import { types } from './actions'

/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.SAVE_NEW_CLAIM:
      draft.hasNewClaim = true
      draft.actionKey = action.payload.actionKey

      draft.token = action.payload.response.token
      draft.location = action.payload.response._links.self
      draft.stateKey = action.payload.response.stateKey
      draft.joinedAppeal = action.payload.response.joinedAppeal

      draft.data = action.payload.data
      break
    case types.UPDATE_CLAIM:
      draft.token = action.payload.response.token
      draft.location = action.payload.response._links.self
      draft.stateKey = action.payload.response.stateKey
      draft.joinedAppeal = action.payload.response.joinedAppeal

      draft.data = { ...draft.data, ...action.payload.data }
      break
    case types.RESET_CLAIM:
      draft = { hasNewClaim: false }
      break
    default:
      break
  }
})

export default reducer
