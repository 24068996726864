import React from 'react'
import { Helmet } from 'react-helmet'
import { globalHistory } from '@reach/router'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { AdapterLuxon as DateAdapter } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import QueryProvider from 'src/providers/QueryProvider'

import {
  StoreProvider as AuthProvider,
  reducer as authReducer,
  initialState as authInitialState,
  AuthWrapper,
} from 'store/auth'
import {
  StoreProvider as NotificationsProvider,
  reducer as notificationsReducer,
  initialState as notificationsInitialState,
  NotificationDisplayer,
} from 'store/notifications'
import {
  StoreProvider as NewClaimProvider,
  reducer as newClaimReducer,
  initialState as newClaimInitialState,
} from 'store/newClaim'

import theme from 'src/theme'
import AmazonStore from '@massappeal/amazon/build/AmazonStore'

import Notices from 'src/Notices'

import { siteName } from 'src/config'

// i18n needs to be imported
// eslint-disable-next-line no-unused-vars
import initI18n from 'src/i18n'

initI18n(globalHistory.location.pathname)

const TopLayout = ({ children }) => (
  <>
    <Helmet>
      <html lang="nl" />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="business.business" />
      <meta property="og:url" content="/" />
      <title>{siteName}</title>
      <meta name="description" content="Appeal platform" />
    </Helmet>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <AuthProvider initialState={authInitialState} reducer={authReducer}>
        <QueryProvider withDevtools>
          <LocalizationProvider dateAdapter={DateAdapter} adapterLocale="nl-NL">
            <AmazonStore.Provider>
              <AmazonStore.InitAmazonStore />
              <NotificationsProvider
                initialState={notificationsInitialState}
                reducer={notificationsReducer}
              >
                <NewClaimProvider
                  initialState={newClaimInitialState}
                  reducer={newClaimReducer}
                >
                  <NotificationDisplayer />
                  <AuthWrapper>{children}</AuthWrapper>
                </NewClaimProvider>
              </NotificationsProvider>
            </AmazonStore.Provider>
          </LocalizationProvider>
        </QueryProvider>
      </AuthProvider>
      <Notices />
    </ThemeProvider>
  </>
)

export default TopLayout
