export const types = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  REMOVE_OLDEST_NOTIFICATION: 'REMOVE_OLDEST_NOTIFICATION',
}

export const actions = {
  addNotification: (
    message,
    { severity, autoHideDuration, id, closeOnClickAway } = {},
  ) => ({
    type: types.ADD_NOTIFICATION,
    payload: {
      id,
      message,
      severity: severity || 'info',
      autoHideDuration: autoHideDuration || 6000,
      closeOnClickAway:
        closeOnClickAway === undefined ? true : closeOnClickAway,
    },
  }),
  removeNotification: ({ id }) => ({
    type: types.REMOVE_NOTIFICATION,
    payload: {
      id,
    },
  }),
  removeOldestNotification: () => ({
    type: types.REMOVE_OLDEST_NOTIFICATION,
  }),
}
