import i18next from 'i18next'
import { setLocale } from 'yup'

// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import locales from './locales'

import { defaultLanguage } from './config'

const supportedLanguages = Object.keys(locales)

const getLanguageFromPath = (path) => {
  const matches = path.match(/^\/(\w\w)\//)

  if (!matches) {
    return defaultLanguage
  }

  const [, lang] = matches

  if (lang && supportedLanguages.includes(lang)) {
    return lang
  }
  return defaultLanguage
}

const initYup = () =>
  setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: 'invalid.default',
      required: 'required',
    },
    string: {
      email: 'invalid.email',
      min: 'short',
      max: 'long',
    },
  })

const initI18n = (path) => {
  const lng = getLanguageFromPath(path)
  i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng,
      fallbackLng: defaultLanguage,
      load: 'languageOnly',
      // debug: true,
      returnObjects: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false,
      },
    })

  // add all translations
  Object.entries(locales).forEach(([key, value]) =>
    Object.entries(value).forEach(([namespace, translations]) =>
      i18next.addResourceBundle(key, namespace, translations),
    ),
  )

  initYup()

  return i18next
}

export default initI18n
