import produce from 'immer'

import { localStorageWrapper } from '@massappeal/amazon'

import { types, authStates, loginLinkStates } from './actions'

/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.SET_AUTHENTICATED:
      draft.isAuthenticated = action.payload.isAuthenticated

      draft.authState = draft.isAuthenticated
        ? authStates.AUTHENTICATED
        : authStates.NOT_AUTHENTICATED
      if (draft.isAuthenticated) {
        // check if the user has joined appeal
        if (!action.payload.joinedAppeal) {
          draft.authState = authStates.AUTHENTICATED_NOT_JOINED
        } else if (action.payload.askToSetPassword) {
          // if he has, check if has set a password
          draft.authState = authStates.AUTHENTICATED_SHOULD_ASK_PASSWORD
        }

        localStorageWrapper.setItem('authenticated', new Date().getTime(), 1)
      } else {
        localStorageWrapper.removeItem('authenticated')
      }
      break
    case types.SET_LOGIN_EMAIL:
      draft.loginEmail = action.payload.loginEmail
      break
    case types.SET_ASKED_TO_SET_PASSWORD:
      draft.authState = authStates.AUTHENTICATED
      break

    case types.SET_HAS_LOGINLINK:
      if (!action.payload.hasLoginLink) {
        draft.loginLinkState = loginLinkStates.NO_LOGINLINK
      } else {
        draft.loginLinkState = action.payload.handled
          ? loginLinkStates.LOGINLINK_HANDLED
          : loginLinkStates.LOGINLINK_PROCESSING
      }
      break

    case types.SET_AUTHENTICATION_STRATEGIES:
      draft.authenticationStrategies = action.payload.authenticationStrategies
      break

    default:
      break
  }
})

export default reducer
