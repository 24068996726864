import { useTranslation } from 'react-i18next'
import { useStore } from './store'
import { actions } from './actions'

const useAddNotification = () => {
  const { t } = useTranslation('notifications')
  const [, dispatch] = useStore()

  return (message, options) => {
    dispatch(actions.addNotification(t(message), options))
  }
}

export default useAddNotification
