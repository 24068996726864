import { sessionTokenTimeToLive } from 'src/config'

import { localStorageWrapper } from '@massappeal/amazon'

import apiClient from './apiClient'

const getUserData = (force) => async () => {
  if (!force) {
    // we have no access to the store in queries,
    // so that's why we have to use localstorage
    let lastAuthenticated = localStorageWrapper.getItem('authenticated')

    if (!lastAuthenticated) {
      // wait 1 tick, maybe it was just set, if we don't do this, this function
      // will error, which means we de-authenticate and unset the localstorage
      // in which case we can never authenticate
      await new Promise((resolve) => {
        setTimeout(resolve, 1)
      })
      lastAuthenticated = localStorageWrapper.getItem('authenticated')
    }

    const err = new Error('Probably not authenticated')
    err.statusCode = -1

    if (!lastAuthenticated) {
      throw err
    }

    const lastAuthenticatedTime = Number(lastAuthenticated)
    if (
      new Date().getTime() - lastAuthenticatedTime >
      sessionTokenTimeToLive * 1000 // date.getTime is in milliseconds
    ) {
      throw err
    }
  }

  return apiClient.get('/api/v1/user/current', { includeCredentials: true })
}

export default getUserData
