export const types = {
  SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_USER_DATA_LOADED: 'SET_USER_DATA_LOADED',
  SET_ASKED_TO_SET_PASSWORD: 'SET_ASKED_TO_SET_PASSWORD',
  SET_HAS_LOGINLINK: 'SET_HAS_LOGINLINK',
  SET_AUTHENTICATION_STRATEGIES: 'SET_AUTHENTICATION_STRATEGIES',
  SET_ASKED_TO_JOIN_APPEAL: 'SET_ASKED_TO_JOIN_APPEAL',
}

export const authStates = {
  LOADING: 'LOADING',
  AUTHENTICATED_SHOULD_ASK_PASSWORD: 'AUTHENTICATED_SHOULD_ASK_PASSWORD',
  AUTHENTICATED_NOT_JOINED: 'AUTHENTICATED_NOT_JOINED',
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
}

export const loginLinkStates = {
  LOADING: 'LOADING',
  NO_LOGINLINK: 'NO_LOGINLINK',
  LOGINLINK_PROCESSING: 'LOGINLINK_PROCESSING',
  LOGINLINK_HANDLED: 'LOGINLINK_HANDLED',
}

export const actions = {
  setAuthenticated: (
    isAuthenticated,
    { hasPassword, skippedSettingPassword, joinedAppeal } = {},
  ) => ({
    type: types.SET_AUTHENTICATED,
    payload: {
      isAuthenticated,
      askToSetPassword: !hasPassword && !skippedSettingPassword,
      joinedAppeal: !!joinedAppeal,
    },
  }),
  setLoginEmail: (loginEmail) => ({
    type: types.SET_LOGIN_EMAIL,
    payload: {
      loginEmail,
    },
  }),
  setAskedToSetPassword: (asked) => ({
    type: types.SET_ASKED_TO_SET_PASSWORD,
    payload: {
      asked,
    },
  }),
  skipJoinAppeal: () => ({
    type: types.SET_ASKED_TO_JOIN_APPEAL,
    payload: {
      asked: true,
    },
  }),
  setHasLoginLink: (hasLoginLink, { handled } = {}) => ({
    type: types.SET_HAS_LOGINLINK,
    payload: {
      hasLoginLink,
      handled,
    },
  }),
  setAuthenticationStrategies: (authenticationStrategies) => ({
    type: types.SET_AUTHENTICATION_STRATEGIES,
    payload: {
      authenticationStrategies,
    },
  }),
}
