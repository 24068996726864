import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient()

const QueryClientProviderComp = ({ children, withDevtools }) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {withDevtools && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
)

export default QueryClientProviderComp
