import auth from './auth.json'
import buttons from './buttons.json'
import faqCategories from './faqCategories.json'
import formFields from './formFields.json'
import errors from './errors.json'
import formErrors from './formErrors.json'
import strings from './strings.json'
import notifications from './notifications.json'
import footer from './footer.json'
import howItWorksSection from './howItWorksSection.json'

export default {
  auth,
  buttons,
  faqCategories,
  formFields,
  formErrors,
  errors,
  strings,
  notifications,
  footer,
  howItWorksSection,
}
