export const types = {
  SAVE_NEW_CLAIM: 'SAVE_NEW_CLAIM',
  UPDATE_CLAIM: 'UPDATE_CLAIM',
  RESET_CLAIM: 'RESET_CLAIM',
}

export const actions = {
  saveNewClaim: (actionKey, response, data = {}) => ({
    type: types.SAVE_NEW_CLAIM,
    payload: {
      actionKey,
      response,
      data,
    },
  }),
  updateClaim: (response, data = {}) => ({
    type: types.UPDATE_CLAIM,
    payload: {
      response,
      data,
    },
  }),
  resetClaim: () => ({
    type: types.RESET_CLAIM,
  }),
}
