import produce from 'immer'

import { types } from './actions'

/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      draft.notificationQueue.push(action.payload)
      break
    case types.REMOVE_OLDEST_NOTIFICATION:
      draft.notificationQueue.shift()
      break
    default:
      break
  }
})

export default reducer
