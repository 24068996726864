import React, { useState, useEffect, useCallback } from 'react'

import { useTheme } from '@mui/material/styles'
import { Snackbar, Alert as MuiAlert } from '@mui/material'

import { useStore } from './store'
import { actions } from './actions'

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    sx={{ width: '100%' }}
    {...props}
  />
))

const NotificationDisplayer = () => {
  const theme = useTheme()
  const [{ notificationQueue }, dispatch] = useStore()
  const [lastNotification, setLastNotification] = useState({})
  const [open, setOpen] = useState(false)
  const [goToNextNotification, setGoToNextNotification] = useState(true)

  const handleClose = (shouldCloseOnClickAway) => (_event, reason) => {
    if (!shouldCloseOnClickAway && reason === 'clickaway') {
      return
    }
    setOpen(false)

    setTimeout(
      () => setGoToNextNotification(true),
      theme.transitions.duration.enteringScreen,
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableDispatch = useCallback(dispatch, [])

  const notificationQueueLength = notificationQueue.length
  const firstInQueue = notificationQueue[0]
  useEffect(() => {
    if (notificationQueueLength > 0 && !open && goToNextNotification) {
      setLastNotification(firstInQueue)
      stableDispatch(actions.removeOldestNotification())
      setOpen(true)
      setGoToNextNotification(false)
    }
  }, [
    notificationQueueLength,
    firstInQueue,
    open,
    goToNextNotification,
    stableDispatch,
  ])

  const { autoHideDuration, message, closeOnClickAway, severity } =
    lastNotification

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose(closeOnClickAway)}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  )
}

export default NotificationDisplayer
