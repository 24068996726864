import { authStates, loginLinkStates } from './actions'

const initialState = {
  authState: authStates.LOADING,
  loginLinkState: loginLinkStates.LOADING,

  authenticationStrategies: null,

  // false instead of null so we can directly input it into
  // the enabled option of a react-query query, which expects a boolean
  isAuthenticated: false,
}

export default initialState
